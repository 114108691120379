import $ from "jquery";
import validate from 'jquery-validation';
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './main';
import axios from "axios";

let isClosed = false;

// document.body.addEventListener('click', closeOnClick(e), true);

const currentLang = document.querySelector("#currentLang").value;

const errorMessages = {
    ar: {
        subject: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
        first_name: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
        last_name: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
        email: {
            required: trans("main.required"),
            email: trans("main.enter_valid_email"),
            minlength: trans("main.minlength"),
        },
        phone: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
            maxlength: trans("main.maxlength"),
        },
        description: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
    },
    en: {
        subject: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
        first_name: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
        last_name: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
        email: {
            required: trans("main.required"),
            email: trans("main.enter_valid_email"),
            minlength: trans("main.minlength"),
        },
        phone: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
            maxlength: trans("main.maxlength"),
        },
        description: {
            required: trans("main.required"),
            minlength: trans("main.minlength"),
        },
    },
};

const removeOverflowTohtmlElement = () => {
    $("html").css("overflow", "hidden");
}
const addOverflowTohtmlElement = () => {
    $("html").css("overflow", "auto");
}
$(document).on("click", function (e){
    if($(".home-main-container").length > -1){
        if (!$(e.target).closest('#header .timing-filter-container').length) {
            if($("#header .timing-filter-container").attr("data-closed") == "false"){
                $("#header .timing-filter-container").removeClass("open");
                $("#header .timing-filter-container").attr("data-closed", "true");
            }
        }
        if (!$(e.target).closest('#header .category-filter-container').length) {
            if($("#header .category-filter-container").attr("data-closed") == "false"){
                $("#header .category-filter-container").removeClass("open");
                $("#header .category-filter-container").attr("data-closed", "true");
            }
        }
        if (!$(e.target).closest('.filter-section-mobile .timing-filter-container').length) {
            if($(".filter-section-mobile .timing-filter-container").attr("data-closed") == "false"){
                $(".filter-section-mobile .timing-filter-container").removeClass("open");
                $(".filter-section-mobile .timing-filter-container").attr("data-closed", "true");
            }
        }
        if (!$(e.target).closest('.filter-section-mobile .category-filter-container').length) {
            if($(".filter-section-mobile .category-filter-container").attr("data-closed") == "false"){
                $(".filter-section-mobile .category-filter-container").removeClass("open");
                $(".filter-section-mobile .category-filter-container").attr("data-closed", "true");
            }
        }
        if (!$(e.target).closest('.filter-section-mobile .category-filter-container').length) {
            if($(".filter-section-mobile .category-filter-container").attr("data-closed") == "false"){
                $(".filter-section-mobile .category-filter-container").removeClass("open");
                $(".filter-section-mobile .category-filter-container").attr("data-closed", "true");
            }
        }
        if (!$(e.target).closest('#header .menu-container').length) {
            if($("#header .menu-container").attr("data-closed") == "false"){
                $("#header .menu-container").removeClass("open");
                $("#header .menu-container").attr("data-closed", "true");
            }
        }
}
    // if($(".menu-container").hasClass("open")){
    //     $(".menu-container").removeClass("open")
    // }
});

// const generateModal = (modalName, modalTarget, modalId, effect='fadein', loaderIsActive=false) => {
//     let `{$modalName}Modal` = new Custombox.modal({
//         content: {
//             effect: effect,
//             target: modalTarget,
//             id: modalId,
//             delay: 100,
//         },
//         overlay : {
//             active: true,
//             color: '#000',
//             opacity: .81,
//             speedIn: 1000,
//         },
//         loader : {
//             active: loaderIsActive,
//             color: '#F61B2E',
//             speed: 1500,
//         }
//     });
//     return modalName;
// }

// $(window).on("load", function(){
//     console.log("hello");
//     var iFrameID = document.getElementById('embedArticleIframe');
    
//     if(iFrameID) {
//         console.log("hello abdallah");
//         console.log("height", iFrameID.height);
//         // here you can make the height, I delete it first, then I make it again
//         iFrameID.style.height = "";
//         iFrameID.style.height = iFrameID.contentWindow.document.body.offsetHeight + "px";
        
//     }
//     console.log("iframe height", iFrameID.style.height);  
// });
// var iFrameID = document.getElementById('embedArticleIframe');
// $("#embedArticleIframe").on("load", function() {
//     console.log("hello abdallah");
//     console.log("origin height", this.style.height);
//     console.log("height content", this.contentWindow.document.body.offsetHeight);
//     this.style.height =
//     this.contentWindow.document.body.offsetHeight + 'px';
// });
// function iframeResizer(){
//     let iframeHolder = document.getElementById('iframeHolder')
//   let iframe = document.getElementById('embedArticleIframe')
//   let iframeHeight
//   let iframeHolderHeight

//   iframeHeight = iframe.contentWindow.document.body.offsetHeight;
//   console.log(iframeHeight)
//   iframeHolderHeight = iframeHolder.style.height = iframeHeight + 'px'
// }
  
/* Redirection  with popups */
let urlParams = new URLSearchParams(window.location.search);

if ($("body").hasClass("homepage")) {
        
    let ENDPOINT = $("#VAR_BASE_URL_HOME").val();
    
    // let pageLang = $('html').attr('lang');
    
    let page = 1;
    let card = 18;
    let countAllNews = 0;
    let url = '';
    const loadMore = (page) => {
        if (urlParams.has("filter") && urlParams.has("categoryFilter")) {
            let filter = urlParams.get("filter");
            let categoryFilter = urlParams.get("categoryFilter");
            url = ENDPOINT +  "?filter=" + filter + "&categoryFilter=" + categoryFilter + "&card=" + card + "&page=" + page;
        } else if (urlParams.has("filter") && !urlParams.has("categoryFilter")) {
            let filter = urlParams.get("filter");
            url = ENDPOINT +  "?filter=" + filter + "&categoryFilter=&card=" + card + "&page=" + page;
        } else {
            url = ENDPOINT +  "?card=" + card + "&page=" + page;
        }
        $.ajax({
                url: url,
                datatype: "html",
                type: "get",
                // beforeSend: function() {
                //     $('.auto-load').show();
                //     $('.no-more-articles').hide();
                // }
            })
            .done(function(response) {
                if (page <= 1) {
                    $("#cards-fill-on-load .small-cards-inner-container").html('');
                }
                if (response[1].length == 0) {
                    // console.log("no more articles");
                    $('.auto-load').hide();
                    $(".no-more-articles").show();
                    return;
                }
                // $('.auto-load').hide();
                $('.no-more-articles').hide();
                countAllNews = response[0];
                $("#cards-fill-on-load .small-cards-inner-container").append(response[1]);
            })
            .fail(function(jqXHR, ajaxOptions, thrownError) {
                console.log('Server error occured');
                $('.no-more-articles').hide();
            });
    }
    // change the number of cards per load between mobile and desktop
    // if ($(window).width() <= 576) {
    //     let card = 3;
    // } else {
    //     let card = 6;
    // }
    loadMore(page);

    // Load More Articles OnLoad
    // let footerHeight = $("footer").height();
    // let footerHeight = 0;
    // $(window).on("scroll", function() {
    //     if($(window).scrollTop() + $(window).height() >= ( $(document).height() - footerHeight )) {
    //         page++;
    //         if ((page * card) <= countAllNews) {
    //             loadMore(page);
    //         }
    //     }
    // });
    $("#loadMoreArticles").on("click", function(){
        // console.log("hello");
        page++;
        if ((page * card) <= countAllNews) {
            loadMore(page);
            // console.log("hello articles are loading");
        } 
        else {
            $('.auto-load').hide();
            $(".no-more-articles").show();
            // console.log("no more articles");
        }
    });
}

function resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
}

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
});

const submitAjaxForm = (button, section, form,  baseUrl) => {
    let form_data = new FormData();
    let other_data = form.serializeArray();
    var submittedEmail = "";
    $.each(other_data, function (key, input) {
        form_data.append(input.name, input.value);
        // console.log(input.name + ":   " + input.value);
        if (input.name == "email") {
            submittedEmail = input.value;
        }
    });
    let ButtonSection = $.trim(button.html());
    // button.text("").append('<div class="lds-ring"><div></div><div></div><div></div>').attr("disabled", true);
    button.append('<div class="lds-ring"><div></div><div></div><div></div>').attr("disabled", true);
    $("#" + section + " .feedbackMsg label").removeClass("invalid").html("");
    $.ajax({
        url: form.attr("action"),
        type: "POST",
        dataType: "json",
        data: form_data,
        contentType: false,
        processData: false,
        success: function (result) {
            button.html(ButtonSection).attr("disabled", false);
            if (result.errors) {
                if (typeof result.errors == "object") {
                    $.each(result.errors, function(key, value) {
                        if ((typeof value == "object") && value.length > 1) {
                            let alertMsg = "";
                            $.each(value, function(key1, value1) {
                                alertMsg += value1 + "<br />\n";
                            });
                            $("#" + section + " .feedbackMsg label").addClass("invalid").html(alertMsg);
                        } else if (typeof value == "object") {
                            $("#" + section + " .feedbackMsg label").addClass("invalid").html(value[0]);
                        }
                    });
                } else {
                    $("#" + section + " .feedbackMsg label").addClass("invalid").html(result.errors);
                }
            } else if (result.success) {
                // showErrorMsg(form, "success", result.success, 3000);
                $("#" + section + " .feedbackMsg label").html(result.success);
                form.trigger("reset");
                let finalUrl = baseUrl;
                if (section == "signup-popup") {
                    finalUrl += "?source=welcome";
                } else if (section == "reset-password") {
                    finalUrl += "?source=reset-password-welcome";
                }
                if (section == "forget-password-popup") {
                    $("#reset-password-email").val(submittedEmail);
                    Custombox.modal.close("forgetPasswordCustombox");
                    resetPasswordModal.open();
                } else {
                    let timeout = 1000;
                    setTimeout(function() {
                        if (result.last_url) {
                            window.location.href = result.last_url;
                        } else {
                            window.location.href = finalUrl;
                        }
                    }, timeout);
                }
                
            }
        },
        error: function(Error) {
            // showErrorMsg(form, "danger", Error, 3000);
            if (Error.responseJSON) {
                if (Error.responseJSON.errors) {
                    let errors = Error.responseJSON.errors;
                    if (typeof errors == "object") {
                        $.each(errors, function(key, value) {
                            if ((typeof value == "object") && value.length > 1) {
                                let alertMsg = "";
                                $.each(value, function(key1, value1) {
                                    alertMsg += value1 + "<br />\n";
                                });
                                $("#" + section + " .feedbackMsg label").addClass("invalid").html(alertMsg);
                            } else if (typeof value == "object") {
                                $("#" + section + " .feedbackMsg label").addClass("invalid").html(value[0]);
                            }
                        });
                    } else {
                        $("#" + section + " .feedbackMsg label").addClass("invalid").html(errors);
                    }
                }
            } else {
                $("#" + section + " .feedbackMsg label").addClass("invalid").html(Error);
            }
            button.html(ButtonSection).attr("disabled", false);
        }
    });
}

const submitSubscribeForm = (button, form) => {
    $("#subscribe-error-message").html("");
    let form_data = new FormData();
    form_data.append("email", $("#subscriber-email").val());
    let ButtonSection = $.trim(button.html());
    button.append('<div class="lds-ring"><div></div><div></div><div></div>').attr("disabled", true);
    $.ajax({
        url: form.attr("action"),
        type: "POST",
        dataType: "json",
        data: form_data,
        contentType: false,
        processData: false,
        success: function (result) {
            button.html(ButtonSection).attr("disabled", false);
            if(result.error){
                if(result.error.includes("is already a list member")){
                    $("#subscribe-error-message").html(trans("main.email_is_subscribed"));
                }
                else {
                    $("#subscribe-error-message").html(trans("main.try_again_later"));
                }
            }
             else if (result.success) {
                subscribeModal.open();
                form.trigger("reset");
                button.html(ButtonSection).attr("disabled", false);
            }
        },
        error: function(Error) {
            $("#subscribe-error-message").html(trans("main.try_again_later"));
            button.html(ButtonSection).attr("disabled", false);
        }
    });
}
const shareOnFacebook = (e) => {
    e.preventDefault();
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href,
      "Facebook-dialog",
      "width=626,height=436"
    );
};
  
const shareOnTwitter = (e) => {
    e.preventDefault();
    let docTitle = document.title;
    let titlesArr = docTitle.split(" - ");
    let title = titlesArr[titlesArr.length - 1];
    let element = document.createElement("div");
    element.innerText = title;
    let sanitizedHTML = element.innerHTML;
    window.open(
        "https://twitter.com/intent/tweet?text=" +
        sanitizedHTML +
        "&url=" +
        window.location.href,
        "Twitter-dialog",
        "width=626,height=436"
    );
};

const shareOnLinkedin = (e) => {
    e.preventDefault();
    window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +  window.location.href,
        'sharer', 'toolbar=0, status=0, width=626, height=436'
        // "https://www.linkedin.com/shareArticle?mini=true&url=" +  window.location.href +"&title=Some%20Title&summary=Some%20Summary&source=YourWebsiteName"
    );
};

let unsubscribeModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#unsubscribe-popup',
        id: 'unsubscribeCustombox',
        delay: 100,
        close: false,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});
let subscribeModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#subscribe-popup',
        id: 'subscribeCustombox',
        delay: 100,
        close: false,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let signupModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#signup-popup',
        id: 'signupCustombox',
        delay: 100,
        close: false,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let signinModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#signin-popup',
        id: 'signinCustombox',
        delay: 100,
        close: false,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let forgetPasswordModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#forget-password-popup',
        id: 'forgetPasswordCustombox',
        delay: 100,
        close: false,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let resetPasswordModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#reset-password-popup',
        id: 'resetPasswordCustombox',
        delay: 100,
        close: false,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let welcomeModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#welcome-popup',
        id: 'welcomeCustombox',
        close: false,
        delay: 100,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let resetPasswordWelcomeModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#reset-password-welcome-popup',
        id: 'resetPasswordWelcomeCustombox',
        close: false,
        delay: 100,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let chooseModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#choose-preferences-popup',
        id: 'choosePreferencesCustombox',
        close: false,
        delay: 100,
        fullscreen: true,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let skipModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#skip-popup',
        id: 'skipCustombox',
        close: false,
        delay: 100,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let sideFilterModal = new Custombox.modal({
    content: {
        effect: 'slide',
        target: '#side-filter-popup',
        id: 'sideFilterCustombox',
        close: false,
        delay: 100,
        //fullscreen: true,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .81,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let videoModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#video-popup',
        id: 'videoCustombox',
        close: false,
        delay: 100,
        //fullscreen: true,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .89,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let isLoggedModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#isLogged-popup',
        id: 'isLoggedCustombox',
        close: false,
        delay: 100,
        //fullscreen: true,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .89,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
}); 

let confirmPayingModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#confirm-paying-popup',
        id: 'confirmPayingCustombox',
        close: false,
        delay: 100,
        //fullscreen: true,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .89,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let rechargeCreditModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#recharge-credit-popup',
        id: 'rechargeCreditCustombox',
        close: false,
        delay: 100,
        //fullscreen: true,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .89,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

let deleteConfirmModal = new Custombox.modal({
    content: {
        effect: 'fadein',
        target: '#delete-confirm-popup',
        id: 'deleteConfirmCustombox',
        close: false,
        delay: 100,
        //fullscreen: true,
    },
    overlay : {
        active: true,
        color: '#000',
        opacity: .89,
        speedIn: 1000,
        close: false,
    },
    loader : {
        active: false,
        color: '#F61B2E',
        speed: 1500,
    }
});

/* OPEN POPUP BY ADDING SOURCE IN URL */
if (urlParams.has("source")) {
    const source = urlParams.get("source");
    // console.log("source", source);
    switch(source) {
        case "welcome": 
            welcomeModal.open();
            removeOverflowTohtmlElement();
            break;
        case "reset-password": 
            resetPasswordModal.open();
            removeOverflowTohtmlElement();
            break;
        case "reset-password-welcome": 
            resetPasswordWelcomeModal.open();
            removeOverflowTohtmlElement();
            break;
        case "choose-preferences": 
            chooseModal.open();
            removeOverflowTohtmlElement();
            break;
        case "forget-password":
            forgetPasswordModal.open();
            removeOverflowTohtmlElement();
            break;
    }
}
if (urlParams.has("token")) {
    const token = urlParams.get("token");
    $("#reset-password-token").val(token);
}
if (urlParams.has("email")) {
    const email = urlParams.get("email");
    $("#reset-password-email").val(email);
}

// CLOSE SELECTED POPUP FUNCTION
const closePopup = (element, popupName) => {
    $(document).on("click", element, function (e) {
        e.preventDefault();
        addOverflowTohtmlElement();
        Custombox.modal.close(popupName);
        $("body").removeClass("custombox-lock");
        $("body").css("overflow", "");
        $("#custombox-" + popupName).find(".feedbackMsg label").html("");
        $("#custombox-" + popupName + ' label.error').each(function() {
            $(this).remove();
        });
    });
}

// OPEN SELECTED POPUP FUNCTION
const openPopup = (element, popupName, includeCloseAction = false, closePopupName = null) => {
    // console.log(popupName.options.content.target);
    if ($(popupName.options.content.target).length) {
        $(document).on("click", element, function (e) {
            e.preventDefault();
            if (includeCloseAction == true) {
                Custombox.modal.close(closePopupName);
                $("body").removeClass("custombox-lock");
                $("body").css("overflow", "");
                $("#custombox-" + closePopupName).find(".feedbackMsg label").html("");
                $("#custombox-" + closePopupName + ' label.error').each(function() {
                    $(this).remove();
                });
            }
            removeOverflowTohtmlElement();
            popupName.open();
            if (!document.body.classList.contains("custombox-lock")) {
                $("body").css("overflow", "hidden");
                $("body").addClass("custombox-lock");
            }

        });
    // } else {
    //     console.log("Target: " + popupName.options.content.target + " not exist!");
    }
}

/* Mobile Menu Item onClick Event */
const scrollToMenuItem = (menuItem) => {
    // if (window.location.href.indexOf("order-summary") > -1) {
    //     $(".checkout-main-container .order-info-container, .checkout-main-container .order-info-mobile-container").css("display", "none");
    // }
    if (window.location.href.indexOf(menuItem) > -1) {
        $('.account-main-container .mobile-menu-item a, .account-main-container .menu-item a').removeClass("selected");
        $('.account-main-container #'+ menuItem + ' a').addClass("selected");
        $('.account-main-container .'+ menuItem + ' a').addClass("selected");
        let link = $(".account-main-container #" + menuItem);
        let left = link.offset().left;
        let width = $(".mobile-menu-inner-container").width();
        let diff = left - width/4;
        $('.mobile-menu-inner-container').animate({
            scrollLeft: $(".mobile-menu-inner-container").scrollLeft()+diff
        });
    }
}

/* Online Payment Card Formatting Expiry Date */
const formatExpiryDate = (event) => {
        let inputChar = String.fromCharCode(event.keyCode);
        let code = event.keyCode;
        let allowedKeys = [8];
        if (allowedKeys.indexOf(code) !== -1) {
          return;
        }
      
        event.target.value = event.target.value.replace(
          /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
        ).replace(
          /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
        ).replace(
          /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
        ).replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
        ).replace(
          /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
        ).replace(
          /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
        ).replace(
          /\/\//g, '/' // Prevent entering more than 1 `/`
        );      
}

const fixedHeaderOnScroll = () => {
    let shrinkHeader = 10;
    $(window).scroll(function() {
        let scroll = getCurrentScroll();
        if ( scroll >= shrinkHeader ) {
            $('header').addClass('fixed');
        }
        else {
            $('header').removeClass('fixed');
        }
    });
}
const  getCurrentScroll = () =>  window.pageYOffset;

// const closeNavbar = () => {
//     $("#navbar").css("width", "0%");
//     setTimeout(function(){
//         $("html").css("overflow", "auto")
//     }, 500);
// }
$(() => {
    // generateModal('signupModal','#signup-popup','signupCustombox');
    // signupModal.open();
    
    // Header Open Side Nav Full Screen
    $(document).on("click", ".user-container.open-navbar", function (e) {
        $("html").css("overflow", "hidden");
        $("#navbar").addClass("show");
    });
    $(document).on("click", "#close-navbar-desktop, #close-navbar-mobile, #navbar .first-block, #navbar .second-block .close-main-container", function (e) {
        // closeNavbar();
        $("#navbar").removeClass("show");
        setTimeout(function(){
            $("html").css("overflow", "auto")
        }, 500);
    });


    /* BILLING PAGE CHECK ONLY ONE CARD */
    $(document).on("click", "#billingForm .current-cards-container  .billing-card-checkbox", function (e) {
        $('.billing-card-checkbox').not(this).prop('checked', false);
    });
   
    fixedHeaderOnScroll();
    if($(".enableScrollToMenuItem").length > 0){
        scrollToMenuItem("my-account");
        scrollToMenuItem("my-packages");
        scrollToMenuItem("card");
        scrollToMenuItem("my-orders");
        scrollToMenuItem("order-summary");
        scrollToMenuItem("billing");
        scrollToMenuItem("receipt");
    }
    
    /* Filter Timing Toggle */
    $(document).on("click", "#header .timing-filter-container .current, #header .category-filter-container .current, .filter-section-mobile .timing-filter-container .current, .filter-section-mobile .category-filter-container .current", function (e) {
        e.preventDefault();
        if($(this).parent().attr("data-closed") == "true"){;
            $(this).parent().addClass("open");
            $(this).parent().attr("data-closed", "false");
        } else {
            $(this).parent().removeClass("open");
            $(this).parent().attr("data-closed", "true");
        }
        
    });
    
    /* Filter Category Toggle */
    // $(document).on("click", ".category-filter-container .current", function (e) {
    //     e.preventDefault();
    //     console.log(!$(".category-filter-container").hasClass("open"));
    //     if(!$(".category-filter-container").hasClass("open")){
    //         $(".category-filter-container").addClass("open");
    //     }
    //     $(".category-filter-container").toggleClass("open");
    // });

    /* Open Menu OnClick Menu Icon */
    $(document).on("click", ".menu-container .icon-container", function (e) {
        e.preventDefault();
        if($(this).parent().attr("data-closed") == "true"){
            $(this).parent().addClass("open");
            $(this).parent().attr("data-closed", "false");
        } else {
            $(this).parent().removeClass("open");
            $(this).parent().attr("data-closed", "true");
        }
    });

    /* Toggle Show Checkbox */
    // $(document).on("click", "#choose-preferences-popup .checkbox-container, #side-filter-popup .checkbox-container", function (e) {
    //     e.preventDefault();
    //     $(this).toggleClass("show-checkbox");
    //     let form = $(this).closest("form");
    //     let checkbox = $(this).find("input[type='checkbox']");
    //     checkbox.attr("checked", !checkbox.attr("checked"));
    //     // let selectedLanguages = form.find("input[name='selectedLanguages[]']");
    //     // if (selectedLanguages.length > 0) {
    //     //     let form_data = new FormData();
    //     //     let other_data = form.serializeArray();
    //     //     $.each(other_data, function (key, input) {
    //     //         form_data.append(input.name, input.value);
    //     //         // console.log(input.name + ":   " + input.value);
    //     //     });
    //     //     form_data.append("check", true);
    //     //     $.ajax({
    //     //         url: form.attr("action"),
    //     //         type: "POST",
    //     //         dataType: "json",
    //     //         data: form_data,
    //     //         contentType: false,
    //     //         processData: false,
    //     //         success: function (result) {
    //     //             $("#selectedLanguages_ar").attr("checked", false);
    //     //             $("#selectedLanguages_en").attr("checked", false);
    //     //             $("#selectedLanguages_fr").attr("checked", false);
    //     //             $(".single-language-container").removeClass("selected");
    //     //             $(".single-language-container").removeClass("disabled");
    //     //             if ((typeof result === "object") && result["enabledLanguage"]) {
    //     //                 if (typeof result["enabledLanguage"] === "object") {
    //     //                     // console.log("result", result["enabledLanguage"]);
    //     //                     let allLang = ["ar", "en", "fr"];
    //     //                     let enabledArr = [];
    //     //                     let disabledArr = [];
    //     //                     $.each(result["enabledLanguage"], function(key, val) {
    //     //                         enabledArr.push(val);
    //     //                         $("#selectedLanguages_" + val).attr("checked", true);
    //     //                         $("#selectedLanguages_" + val).next().addClass("selected");
    //     //                         console.log(key + ": " + val);
    //     //                     });
    //     //                     console.log("allLang", allLang);
    //     //                     console.log("enabledArr", enabledArr);
    //     //                     $.grep(allLang, function(el) {
    //     //                         if ($.inArray(el, enabledArr) == -1) {
    //     //                             disabledArr.push(el);
    //     //                             $("#selectedLanguages_" + el).next().addClass("disabled");
    //     //                         }
    //     //                     });
    //     //                     console.log("disabledArr", disabledArr);
    //     //                 }
    //     //             } else {
    //     //                 console.log("result", result);
    //     //             }
    //     //         },
    //     //         error: function(Error) {
    //     //             console.log("Error", Error);
    //     //         }
    //     //     });
    //     // }
    // });

    /* Check If Article Is Paid Or Not */
    $(document).on("click", ".home-main-container .single-card-container", function (e) {
        e.preventDefault();
        if ($("#isLogged-popup").length) {
            const dataToSend = {
                _token: $('meta[name="csrf-token"]').attr("content"),
                section: "saveIntoSession",
                lastFrontUrl: $(this).attr("href"),
            }
            $.ajax({
                url: $("#VAR_BASE_URL_SUBSCRIBE").val(),
                type: "post",
                data: dataToSend,
                success: function(response) {
                    console.log(response, "response");
                    isLoggedModal.open();
                },
                error: function(error) {
                    console.log(error, "error");
                    isLoggedModal.open();
                }
            });
        } else {
            window.location = $(this).attr('href');
        }
        // // console.log($(this).hasClass("voice-card"));
        // // return;
        // // if(typeof $(this).data('paid') !== 'undefined' && $(this).attr("data-free") == "false"){
        // if($(this).attr("data-free").trim() == "true"){
        //     if($(this).hasClass("voice-card")){
        //         $(this).find(".recording-icon, .recording-lines-icon").css("display", "none");
        //         $(this).find(".audio-player-wrapper").css("display", "block");
        //         let audio = $(this).find("audio");
        //         audio["0"].play();
        //         return;
        //     }
        //     window.location = $(this).attr('href');
        // }
        // else{
        //     if( parseInt($(this).attr("data-price").trim()) <= $("#user-wallet").val().trim() ){
        //         $("#new-credit-price").text($(this).attr("data-price").trim());
        //         $("#confirm-paying-popup a").attr("data-news-id", $(this).attr("data-id").trim());
        //         $("#confirm-paying-popup a").attr("data-href", $(this).attr("href"));
        //         confirmPayingModal.open();
        //     }
        //     else{
        //         $("#article-price").text($(this).attr("data-price").trim());
        //         rechargeCreditModal.open();
        //     }
        // }
    });

    /* On Delete Package From Order Summary Page , Open Delete Confirmation Popup */
    $(document).on("click", ".order-summary-page #openDeleteConfirmPopup", function (e) {
        e.preventDefault();
        let button = $(this);
        let actionForm = button.data("form-action");
        let orderId = button.data("order-id");
        $("#delete-confirm-popup #removeCartItem").attr("data-form-action", actionForm).attr("data-order-id", orderId);
        deleteConfirmModal.open();
    });

    /* Delete Package From Order Summary */ 
    $(document).on("click", "#removeCartItem", function (e) {
        e.preventDefault();
        let button = $(this);
        let oldBtnName = button.text();
        let actionForm = button.data("form-action");
        let orderId = button.data("order-id");
        button.text("Loading ").append('<span class="fa fa-spinner fa-spin"></span>').attr("disabled", true);
        let dataToSend = {
            "_token": $('meta[name="csrf-token"]').attr("content"),
            "id": orderId,
        }
        $.post(actionForm, dataToSend).done(function(response) {
            // console.log(response);return false;
            if (!jQuery.isEmptyObject(response) && (response["errors"] == 0)) {
                window.location.reload();
            } else {
                alert(response["message"]);
            }
            button.html(oldBtnName).attr("disabled", false);
        }).fail(function(Error) {
            // console.log(Error);return false;
            button.html(oldBtnName).attr("disabled", false);
            alert("Error, please try again later");
        });
    });
    
    /* Update Package Quantity Inside Order Summary Page */ 
    $(document).on("click", ".order-summary-page .sign-container", function (e) {
        e.preventDefault();
        let current = $(this);
        // $(this).bind('click', function(){ return false; });
        let id = current.attr("id");
        let packageCost = parseInt(current.parent().parent().parent().parent().find("#plan-worth").text().trim());
        let total = parseInt($("#totalPrice #price").text().trim());
        let quantityValue = current.parent().find("#quantity-number").text().trim();
        let actionForm = current.parent().data("form-action");
        let orderId = current.parent().data("order-id");
        // console.log("--------------------------------------------------------------------------------------------------------------------------------");
        // console.log("current clicked element", current);
        // console.log("id", id);
        // console.log("quantity value", quantityValue);
        // console.log("package cost", packageCost);
        // console.log("total", total);
        
        // console.log("form action", actionForm);
        // console.log("order id", orderId);
        // return;
        current.parent().find("#minimum-quantity").text("");
        if(id == "decrease"){
            if(quantityValue > 1){
                quantityValue--;
            } else{
                quantityValue = 1;
                current.parent().find("#minimum-quantity").text(trans("main.minimum_accepted_quantity"));
                return;
            }   
        } else if(id == "increase"){
            // console.log("hellof rom increase");
            if(quantityValue >= 1){
                quantityValue++;
            } else{
                quantityValue = 1;
                current.parent().find("#minimum-quantity").text(trans("main.minimum_accepted_quantity"));
                return;
            }
        }
        let dataToSend = {
            "_token": $('meta[name="csrf-token"]').attr("content"),
            "id": orderId,
            "quantity": quantityValue
        }
        $.post(actionForm, dataToSend).done(function(response) {
            if (!jQuery.isEmptyObject(response) && (response["errors"] == 0)) {
                current.parent().find("#quantity-number").text(quantityValue);
                if(id == "decrease"){
                    total = total - packageCost;
                    $("#totalPrice #price").text(total);
                } else if(id == "increase"){
                    total = total + packageCost;
                    $("#totalPrice #price").text(total);
                }
                // $(this).unbind('click');
            } else {
                current.parent().find("#minimum-quantity").text(response["message"]);
                // $(this).unbind('click');
            }
        }).fail(function(Error) {
            current.parent().find("#minimum-quantity").text("Error, please try again later");
            // $(this).unbind('click');
        });
    });
    // Subscribe popup
    closePopup("#subscribe-popup .close-icon-container, #subscribe-popup a", "subscribeCustombox");
    closePopup("#unsubscribe-popup .close-icon-container, #unsubscribe-popup a", "unsubscribeCustombox");

    // Article Details Page popups */
    openPopup("#isLogged-popup .login", signinModal, true, this);
    openPopup("#isLogged-popup .register", signupModal, true, this);
    closePopup("#isLogged-popup .close-icon-container", "isLoggedCustombox");
    closePopup("#confirm-paying-popup .close-icon-container", "confirmPayingCustombox");
    closePopup("#confirm-paying-popup .cancel", "confirmPayingCustombox");
    closePopup("#recharge-credit-popup .close-icon-container", "rechargeCreditCustombox");

    /* ORDER SUMMARY CONFIRM DELETE PACKAGE POPUP */
    closePopup("#delete-confirm-popup .close-icon-container", "deleteConfirmCustombox");
    closePopup("#delete-confirm-popup .cancel", "deleteConfirmCustombox");

    /* Sign in Popup */
    openPopup("#signup-popup #signin-link", signinModal, true, "signupCustombox");
    openPopup("header .user-container.login", signinModal);
    openPopup(".landing-page #landingSignUp", signupModal);
    closePopup("#signin-popup .close-icon-container", "signinCustombox");

    /* Sign up Popup */
    openPopup("#signin-popup #signup-link", signupModal, true, "signinCustombox");
    closePopup("#signup-popup .close-icon-container", "signupCustombox");

    /* ForgetPassword Popup */
    openPopup("#signin-popup .forget-link", forgetPasswordModal, true, "signinCustombox");
    closePopup("#forget-password-popup .close-icon-container", "forgetPasswordCustombox");

    /* ResetPassword Popup */
    // openPopup("#forget-password-popup .forget-password-submit-button", resetPasswordModal, true, this);
    closePopup("#reset-password-popup .close-icon-container", "resetPasswordCustombox");

    /* Welcome Popup */
    closePopup("#welcome-popup .close-icon-container", "welcomeCustombox");

    /*Reset Password Welcome Popup */
    closePopup("#reset-password-welcome-popup .close-icon-container, #reset-password-welcome-popup a", "resetPasswordWelcomeCustombox");


    /* Choose Popup */
    openPopup("#welcome-popup a", chooseModal, true, "welcomeCustombox");
    // closePopup("#welcome-popup .close-icon-container", "welcomeCustombox");

    /* Skip Popup */
    openPopup("#choose-preferences-popup #skip-button", skipModal);
    closePopup("#skip-popup #cancel-skip-button", "skipCustombox");
    closePopup("#skip-popup .close-icon-container", "skipCustombox");


    /* Side Filter Popup */
    openPopup("header .preferences-container", sideFilterModal);
    openPopup(".filter-section-mobile .preferences-container", sideFilterModal);
    closePopup("#side-filter-popup .close-icon-container", "sideFilterCustombox");
    
    /* Open Video Popup By Play-icon */
    $(document).on("click", ".video-play-icon", function (e) {
        e.preventDefault();
        if($(this).parent().parent().parent().hasClass("arabic-card")){
            $("#video-popup .video-title").addClass("arabic-title");
        } else {
            if($("#video-popup .video-title").hasClass("arabic-title")){
                $("#video-popup .video-title").removeClass("arabic-title");
            }
        }
        let videoKey = $(this).data("key");
        let videoPlatform = $(this).data("platform");
        let titleValue = $(this).parent().parent().find(".title-container").html();
        if(videoPlatform == "Youtube"){
            $("#video-popup iframe").attr("src", "https://www.youtube.com/embed/" + videoKey);
        }
        else if (videoPlatform == "Vimeo"){
            $("#video-popup iframe").attr("src", "https://player.vimeo.com/video/" + videoKey);
        }
        $("#video-popup .video-title").html(titleValue);
        videoModal.open();
    });

    /* Open Video Popup By Play-icon On Article Featured Image */
    $(document).on("click", ".article-video-icon", function (e) {
        e.preventDefault();
        let videoKey = $(this).data("key");
        let titleValue = $(this).parent().parent().parent().find(".article-main-title").html();
        $("#video-popup iframe").attr("src", "https://www.youtube.com/embed/" + videoKey);
        $("#video-popup .video-title").html(titleValue);
        videoModal.open();
    });

    /* Close Video Popup By x-icon*/
    closePopup("#video-popup .x-icon-container", "videoCustombox");

    /* Event onClick Of --- Pay from wallet --- button inside article details page*/
    $(document).on("click", "#payForPremiumButton", function (e) {
        e.preventDefault();
        let isLoggedin = $("#isLoggedin").val();
        if(isLoggedin == "true"){
            confirmPayingModal.open();
        } else {
            isLoggedModal.open();
        }  
    });

    /* Sign up Form Validation*/
    $(document).on("click", "#signup-submit-button", function (e) {
        e.preventDefault();
        let form = $("#signup-form");
        if (form.valid()) {
            submitAjaxForm($(this), "signup-popup", form, $("#VAR_BASE_URL_HOME").val());
        } else {
            return false
        }
    });
    var SHOW_PHONE_FIELD = ($("#SHOW_PHONE_FIELD").length > 0) ? $("#SHOW_PHONE_FIELD").val() : "";
    // console.log("SHOW_PHONE_FIELD", SHOW_PHONE_FIELD);
    var signupFormRules = [];
    signupFormRules["name"] = {
        minlength: 3,
        maxlength: 50,
    };
    if (SHOW_PHONE_FIELD == true) {
        signupFormRules["phone"] = {
            minlength: 8,
            maxlength: 20,
        };
    }
    signupFormRules["password"] = {
        minlength: 8,
        maxlength: 50,
    };
    signupFormRules["password_confirmation"] = {
        minlength: 8,
        maxlength: 50,
        equalTo: "#signup-form #password",
    };
    var signupFormMessages = [];
    signupFormMessages["name"] = {
        required: trans("main.enter_full_name"),
        minlength: trans("main.enter_minlength_3"),
        maxlength: trans("main.enter_maxlength_50"),
    };
    signupFormMessages["email"] = {
        required: trans("main.enter_email"),
        email: trans("main.enter_valid_email"),
    };
    if (SHOW_PHONE_FIELD == true) {
        signupFormMessages["phone"] = {
            required: trans("main.enter_phone"),
            minlength: trans("main.enter_minlength_8"),
            maxlength: trans("main.enter_maxlength_20"),
        };
    }
    signupFormMessages["country"] = {
        required: trans("main.enter_country"),
    };
    signupFormMessages["password"] = {
        required: trans("main.enter_password"),
        minlength: trans("main.enter_minlength_8"),
        maxlength: trans("main.enter_maxlength_50"),
    };
    signupFormMessages["password_confirmation"] = {
        required: trans("main.enter_confirm_password"),
        minlength: trans("main.enter_minlength_8"),
        maxlength: trans("main.enter_maxlength_50"),
        equalTo: trans("main.equalTo")
    };
    $("#signup-form").validate({
        rules: signupFormRules,
        messages: signupFormMessages,
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    /* Sign in Form Validation*/
    $(document).on("click", "#signin-submit-button", function (e) {
        e.preventDefault();
        let form = $("#signin-form");
        if (form.valid()) {
            submitAjaxForm($(this), "signin-popup", form, $("#VAR_BASE_URL_HOME").val());
            // form.submit();
            // form[0].reset();
        } else {
            return false
        }
    });
    $("#signin-form").validate({
        rules: {
            email: {
                required: true,
                email: true,
            },
            password: {
                required: true,
            },
        },
        messages: {
            // user_login: {
            //     required: trans("main.enter_username"),
            // },
            email: {
                required: trans("main.enter_email"),
                email: trans("main.enter_valid_email"),
            },
            password: {
                required: trans("main.enter_password"),
            },
        },
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    /* Forgot Password Form Validation*/
    $(document).on("click", "#forget-password-submit-button", function (e) {
        e.preventDefault();
        let form = $("#forget-password-form");
        if (form.valid()) {
            submitAjaxForm($(this), "forget-password-popup", form, $("#VAR_BASE_URL").val());
            // form.submit();
            // form[0].reset();
        } else {
            return false
        }
    });
    $("#forget-password-form").validate({
        messages: {
            email: {
                required: trans("main.enter_email"),
                email: trans("main.enter_valid_email"),
            },
        },
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    /* Reset Password Form Validation*/
    $(document).on("click", "#reset-password-submit-button", function (e) {
        e.preventDefault();
        let form = $("#reset-password-form");
        if (form.valid()) {
            submitAjaxForm($(this), "reset-password-popup", form, $("#VAR_BASE_URL_HOME").val());
            // form.submit();
            // form[0].reset();
        }
        else {
            return false
        }
    });
    $("#reset-password-form").validate({
        rules: {
            password: {
                minlength: 8,
                maxlength: 50,
            },
            password_confirmation: {
                minlength: 8,
                maxlength: 50,
                equalTo: "#reset-password-form #reset-password",
            },
        },
        messages: {
            password: {
                required: trans("main.enter_password"),
                minlength: trans("main.enter_minlength_8"),
                maxlength: trans("main.enter_maxlength_50"),
            },
            password_confirmation: {
                required: trans("main.enter_confirm_password"),
                minlength: trans("main.enter_minlength_8"),
                maxlength: trans("main.enter_maxlength_50"),
                equalTo: trans("main.equalTo")
            },
        },
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    /* Profile Form Validation*/
    $(document).on("click", "#profile-submit-button", (e) => {
        e.preventDefault();
        let form = $("#personal-information-form");
        if (form.valid()) {
            form.submit();
            form[0].reset();
        }
        else {
            return false
        }
    });
    var personalInformationFormRules = [];
    personalInformationFormRules["name"] = {
        minlength: 3,
        maxlength: 50,
    };
    if (SHOW_PHONE_FIELD == true) {
        personalInformationFormRules["phone"] = {
            minlength: 8,
            maxlength: 20,
        };
    }
    personalInformationFormRules["password"] = {
        minlength: 8,
        maxlength: 50,
    };
    personalInformationFormRules["password_confirmation"] = {
        minlength: 8,
        maxlength: 50,
        equalTo: "#personal-information-form #password",
    };
    var personalInformationFormMessages = [];
    personalInformationFormMessages["name"] = {
        required: trans("main.enter_full_name"),
        minlength: trans("main.enter_minlength_3"),
        maxlength: trans("main.enter_maxlength_50"),
    };
    personalInformationFormMessages["email"] = {
        required: trans("main.enter_email"),
        email: trans("main.enter_valid_email"),
    };
    if (SHOW_PHONE_FIELD == true) {
        personalInformationFormMessages["phone"] = {
            required: trans("main.enter_phone"),
            minlength: trans("main.enter_minlength_8"),
            maxlength: trans("main.enter_maxlength_20"),
        };
    }
    personalInformationFormMessages["country"] = {
        required: trans("main.enter_country"),
    };
    personalInformationFormMessages["password"] = {
        required: trans("main.enter_password"),
        minlength: trans("main.enter_minlength_8"),
        maxlength: trans("main.enter_maxlength_50"),
    };
    personalInformationFormMessages["password_confirmation"] = {
        required: trans("main.enter_confirm_password"),
        minlength: trans("main.enter_minlength_8"),
        maxlength: trans("main.enter_maxlength_50"),
        equalTo: trans("main.equalTo")
    };
    $("#personal-information-form").validate({
        rules: personalInformationFormRules,
        messages: personalInformationFormMessages,
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    /* Add Online Payment Form Validation*/
    $(document).on("click", "#saveCard_submit_btn", (e) => {
        e.preventDefault();
        let form = $("#cardForm");
        if (form.valid()) {
            form.submit();
            form[0].reset();
        } else {
            return false
        }
    });
    $(document).on("click", "#confirm_submit_btn", (e) => {
        e.preventDefault();
        var button = $(this);
        var ButtonSection = $.trim(button.text());
        var form = $("#cardForm");
        if (form.valid()) {
            // console.log(ButtonSection);
            // retirn false;
            button.append('<div class="lds-ring"><div></div><div></div><div></div>').attr("disabled", true); 
            var stripe_publishable_key = form.data("stripe-publishable-key");
            // console.log(stripe_publishable_key);
            Stripe.setPublishableKey(stripe_publishable_key);
            Stripe.createToken({
                number: $("#credit_card_number").val(),
                cvc: $("#CVC").val(),
                exp_month: $("#expiry_month").val(),
                exp_year: $("#expiry_year").val(),
                address_country: $("#country").val(),
                address_city: $("#city").val(),
                address_line1: $("#address_line1").val(),
                address_line2: $("#address_line2").val(),
                address_state: $("#state").val(),
                address_zip: $("#zip").val(),
            }, stripeResponseHandler);
            // button.html(ButtonSection).attr("disabled", false);
            // form.submit();
            // form[0].reset();
        } else {
            return false
        }
    });
    function stripeResponseHandler(status, response) {
        // console.log("response", response);
        if (response.error) {
            $(".error-message-front").addClass("error-message").text(trans(response.error.message)).show();
        } else {
            var token = response["id"];
            // console.log(token);
            $("#confirm_submit_btn").append('<div class="lds-ring"><div></div><div></div><div></div>').attr("disabled", true);
            $("#cardForm").append('<input type="hidden" name="stripeToken" value="' + token + '" />');
            $("#cardForm").get(0).submit();
        }
    }
    $("#SavedCardForm").validate({
        rules: {
            payment_method_id: "required",
        },
        messages: {
            payment_method_id: {
                required: trans("main.required_input_checkbox"),
            },
        },
        errorElement : 'span',
        errorPlacement: function(error, element) { 
             $(".error-message-front").addClass("error-message").text($(error).text());
            
        }
    });

    $("#cardForm").validate({
        rules: {
            card_holder_name: {
                required: true,
            },
            credit_card_number: {
                required: true,
            },
            country: {
                required: true,
            },
            city: {
                required: true,
            },
            address_line1: {
                required: true,
            },
            zip: {
                required: true,
            },
            CVC: {
                required: true,
            },
            expiry_month: {
                required: true,
                minlength: 2,
                maxlength: 2
            },
            expiry_year: {
                required: true,
                minlength: 2,
                maxlength: 2
            },
            last_four: {
                minlength: 4,
                maxlength: 4,
            },
        },
        messages: {
            card_holder_name: {
                required: trans("main.required"),
            },
            credit_card_number: {
                required: trans("main.required"),
            },
            CVC: {
                required: trans("main.required"),
            },
            expiry_month: {
                required: trans("main.required"),
                minlength: trans("main.enter_minlength_2"),
                maxlength: trans("main.enter_maxlength_2"),
            },
            expiry_year: {
                required: trans("main.required"),
                minlength: trans("main.enter_minlength_2"),
                maxlength: trans("main.enter_maxlength_2"),
            },
            last_four: {
                required: trans("main.required"),
                minlength: trans("main.enter_minlength_4"),
                maxlength: trans("main.enter_maxlength_4"),
            },
            country: {
                required: trans("main.enter_country"),
            },
            city: {
                required: trans("main.required"),
            },
            address_line1: {
                required: trans("main.required"),
            },
            zip: {
                required: trans("main.required"),
            },
        },
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    /* Contact us Form Validation*/
    function validateErrors(errors) {
        if (typeof errors == "object") {
            $.each(errors, function(key, value) {
                // console.log(key);
                // console.log(value);
                $("#" + key).removeClass("valid").addClass("error");
                if ((typeof value == "object") && value.length > 1) {
                    // console.log(value.length, "value object");
                    let alertMsg = "";
                    $.each(value, function(key1, value1) {
                        alertMsg += value1 + "\n";
                    });
                    $("#" + key + "-error").text(alertMsg).show();
                    $("#" + key).removeClass("valid").addClass("is-invalid");
                } else if (typeof value == "object") {
                    // console.log("value string");
                    $("#" + key + "-error").text(value[0]).show();
                    $("#" + key).removeClass("valid").addClass("is-invalid");
                }
            });
        }
    }
    const contactUsFormAxios = () => {
        let form = $("#contactUsForm");
        axios.post(form.attr("action"), new FormData(document.getElementById('contactUsForm')))
        .then(function(response) {
            // console.log(response);
            if (!jQuery.isEmptyObject(response) && (response.data.validation_errors)) {
                validateErrors(response.data.validation_errors);
                $("#contact-submit-button").attr("disabled", false).text($("#contact-submit-button").attr("data-btn-text"));
                form[0].reset();
            } else if (response.data.success == true) {
                $("#contact-submit-button").attr("disabled", false).text($("#contact-submit-button").attr("data-btn-text"));
                form[0].reset();
                if (!jQuery.isEmptyObject(response) && (response.data.redirect_url)) {
                    // setTimeout(function() {
                        window.location.href = response.data.redirect_url;
                    // }, 1000);
                } else {
                    $("#responseSuccessMessage").css("display", "inline").text(response.data.message);
                }
            } else {
                $("#responseErrorMessage").css("display", "inline").text(response.data.message);
                $("#contact-submit-button").attr("disabled", false).text($("#contact-submit-button").attr("data-btn-text"));
                form[0].reset();
            }
        })
        .catch(function(error) {
            $("#responseErrorMessage").css("display", "inline").text(response.data.message);
            $("#contact-submit-button").attr("disabled", false).text($("#contact-submit-button").attr("data-btn-text"));
            form[0].reset();
        });
    }
    const contactUsForm = () => {
        $("#contact-submit-button").on("click", function(e){
            e.preventDefault();
            let form = $("#contactUsForm");
            $("#responseSuccessMessage").css("display", "none");
            $("#responseErrorMessage").css("display", "none");
            $("#responseCaptchaErrorMessage").css("display", "none");
            if(form.valid()){
                $("#contact-submit-button").attr("disabled", true).text($("#contact-submit-button").attr("data-loading-text"));
                let VAR_CAPTCHA_ACTIVATE = $("#VAR_CAPTCHA_ACTIVATE").val();
                if (VAR_CAPTCHA_ACTIVATE == 1) {
                    let VAR_CAPTCHA_SITE_KEY = $("#VAR_CAPTCHA_SITE_KEY").val();
                        grecaptcha.execute(VAR_CAPTCHA_SITE_KEY, {
                        action: "/contactUsForm"
                    }).then(function(tokenCaptcha) {
                        // console.log(tokenCaptcha); return false;
                        $("#g-recaptcha-response").val(tokenCaptcha);
                        if (tokenCaptcha == "") {
                            $("#responseCaptchaErrorMessage").css("display", "inline");
                            return false;
                        } else {
                            contactUsFormAxios();
                        }
                    }, function(reason) {
                        $("#responseCaptchaErrorMessage").css("display", "inline");
                        return false;
                    });
                }
                else{
                    contactUsFormAxios();
                }
            } else{
                return false;
            }
        });
        $("#contactUsForm").validate({
            rules: {
                name: {
                    required: true,
                },
                email: {
                    required: true,
                    minlength: 3,
                },
                message: {
                    required: true,
                    minlength: 3,
                },
            },
            messages: {
                subject: {
                    required: errorMessages[currentLang]["subject"]["required"],
                },
                first_name: {
                    required: errorMessages[currentLang]["first_name"]["required"],
                },
                last_name: {
                    required: errorMessages[currentLang]["last_name"]["required"],
                },
                email: {
                    required: errorMessages[currentLang]["email"]["required"],
                    email: errorMessages[currentLang]["email"]["email"],
                    minlength: errorMessages[currentLang]["email"]["minlength"],
                },
                // phone: {
                //     required: errorMessages[currentLang]["phone"]["required"],
                //     minlength: errorMessages[currentLang]["phone"]["minlength"],
                //     maxlength: errorMessages[currentLang]["phone"]["maxlength"],
                // },
                description: {
                    required: errorMessages[currentLang]["description"]["required"],
                    minlength: errorMessages[currentLang]["description"]["minlength"],
                },
            },
            errorPlacement: function(error, element) {
                error.insertAfter(element);
            },
        });
    }
    contactUsForm();

     /* Add Pre-paid Card Form Validation*/
     $(document).on("click", "#prepaidCardForm button", (e) => {
        e.preventDefault();
        let form = $("#prepaidCardForm");
        if (form.valid()) {
            form.submit();
            form[0].reset();
        } else {
            return false
        }
    });
    $("#prepaidCardForm").validate({
        rules: {
            serial_number: {}
        },
        messages: {
            serial_number: {
                required: trans("main.required"),
            },
        },
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    
    /* Add Online Payment Form Inside Billing Page ( Payment Method Page )Validation*/
    $(document).on("click", "#billingForm button", (e) => {
        e.preventDefault();
        let form = $("#billingForm");
            form.validate(
                {
                    rules: {
                        card_holder_name: {
                            required: true,
                        },
                        credit_card_number: {
                            required: true,
                        },
                        country: {
                            required: true,
                        },
                        city: {
                            required: true,
                        },
                        address_line1: {
                            required: true,
                        },
                        zip: {
                            required: true,
                        },
                        expiration_date: {
                            minlength: 5,
                            maxlength: 5,
                            required: true,
                        },
                        last_four: {
                            required: true,
                            minlength: 4,
                            maxlength: 4,
                        },
                    },
                    messages: {
                        card_holder_name: {
                            required: trans("main.required"),
                        },
                        credit_card_number: {
                            required: trans("main.required"),
                        },
                        expiration_date: {
                            required: trans("main.required"),
                            minlength: trans("main.enter_minlength_5"),
                            maxlength: trans("main.enter_maxlength_5"),
                        },
                        last_four: {
                            required: trans("main.required"),
                            minlength: trans("main.enter_minlength_4"),
                            maxlength: trans("main.enter_maxlength_4"),
                        },
                        country: {
                            required: trans("main.required"),
                        },
                        city: {
                            required: trans("main.required"),
                        },
                        address_line1: {
                            required: trans("main.required"),
                        },
                        zip: {
                            required: trans("main.required"),
                        },
                    },
                    errorPlacement: function(error, element) {
                        error.insertAfter(element);
                    },
                }
            );
        if (form.valid()) {
            // console.log("hello from valid");
            return ;
            form.submit();
            form[0].reset();
        } else {
            // console.log("hello from unvalid")
            return false;
        }
    });
   

    /* Payment Card - Set As Default  Change Event*/
    // $(document).on("change", '#cardForm input[type="checkbox"]', (e) => {
    //     if ( $(this).is(":checked") ) {
    //         $(this).val("1");
    //     } else if ( $(this).not(":checked") ) {
    //         $(this).val("0");
    //     }
    // });

    /* Expand SocialMedia Container That Includes the Links To Share Current Article*/
    $(document).on("click", ".article-details-container .share-icon-container", function (e) {
        e.preventDefault();
        $("#article-share-social-media-container").toggleClass("opened");
    });

    //Article Details Page - Related Articles Slider
    $(".articles-slider-inner-container").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        prevArrow: $(".article-details-container .related-articles-container .slider-arrow-prev"),
        nextArrow: $(".article-details-container .related-articles-container .slider-arrow-next"),
        rtl: $("body").hasClass("rtl") ? true : false,
        responsive: [
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
    });

    /* Sharing functionalities */
    $(document).on("click", ".facebookShare", (e) => {
        shareOnFacebook(e);
    });
    $(document).on("click", ".twitterShare", (e) => {
        shareOnTwitter(e);
    });
    $(document).on("click", ".linkedinShare", (e) => {
        shareOnLinkedin(e);
    });

    /* Inpot Expiry Date Formatting Keyup Event */
    //$(document).on("keyup", "#expiration_date", (e) => {
    //    formatExpiryDate(e);
    //});

    /* Billing Page Switch Between Payment Method Tabs */
    // $(".billing-page #tabs .single-tab:not(:first)").addClass("inactive");
    //$(".billing-page .order-add-payment-form .tab-main-container").hide();
    //$(".billing-page .order-add-payment-form .tab-main-container:first").show();
    $(".billing-page #tabs .single-tab").on("click", function() {
        let t = $(this).attr("id")
        if ($(this).hasClass("inactive")) {
            $(".billing-page #tabs .single-tab").addClass("inactive");
            $(this).removeClass("inactive");
            if (t == 'tab-online-payment-card-form') {
                $(".billing-page .order-add-payment-form .tab-main-container").hide();
                $(".billing-page .online-payment-card-form .tab-main-container").fadeIn("slow");
            } else {
                $(".billing-page .online-payment-card-form .tab-main-container").hide();
                $(".billing-page .order-add-payment-form .tab-main-container").fadeIn("slow");
            }
        }
    });

    $(window).width() > 768 ?
    $(".billing-page .payment-inputs-container").toggleClass("full-height-desktop") : 
    $(".billing-page .payment-inputs-container").toggleClass("full-height-mobile");
    
    /* faq section expand */
    $(".select-packages-page .single-question .icon-container, .landing-page .single-question .icon-container").on("click", function () {
        $(this).parent().parent().toggleClass("show");
    });

    /* Side Filter Select Languages*/
    $(document).on("click", ".single-language-container", function(e) {
        e.preventDefault();
        if (!$(this).hasClass("disabled")) {
            $(this).toggleClass("selected");
            let checkbox = $(this).parent().find("input[type='checkbox']");
            checkbox.attr("checked", !checkbox.attr("checked"));
        }
    });

    /* Side Filter Form*/
    $(document).on("click", "#sideFilterSubmitButton", (e) => {
        let form = $("#sideFilterForm");
        // let form_data = new FormData();
        // let other_data = form.serializeArray();
        // $.each(other_data, function (key, input) {
        //     form_data.append(input.name, input.value);
        //     // console.log(input.name + ":   " + input.value);
        // });
        // // console.log(form.attr("action"));
        // form_data.append("ajaxLoad", true);
        // $.ajax({
        //     url: form.attr("action"),
        //     type: "POST",
        //     data: form_data,
        //     contentType: false,
        //     processData: false,
        //     success: function (result) {
        //         console.log("result", result);
        //     },
        //     error: function(Error) {
        //         console.log("Error", Error);
        //     }
        // });
        form.submit();
    });
    
    /* Subscribe Form Validation*/
    $(document).on("click", "#subscribe-button", function (e) {
        e.preventDefault();
        let form = $("#subscribeForm");
        if (form.valid()) {
            submitSubscribeForm($(this), form);
        } else {
            return false;
        }
    });
    $("#subscribeForm").validate({
        rules:{
            email: {
                required: true,
            }
        },
        messages: {
            email: {
                required: trans("main.enter_email"),
            },
        },
        errorPlacement: function(error, element) {
            error.insertAfter(element);
        },
    });

    $(document).on("click", ".preference-single-inner-container", function (e) {
        e.preventDefault();
        $(this).find(".checkbox-container").toggleClass("show-checkbox");
        var checkbox_item = $(this).find("input[type='checkbox']");
        checkbox_item.attr("checked", !checkbox_item.attr("checked"));
    });

    $(document).on("click", "#selectAllPubPref1, #selectAllPubPref2", function (e) {
        var checkbox_item = $(this);
        // console.log(checkbox_item);
        checkbox_item.attr("checked", !checkbox_item.attr("checked"));
        var is_checked = checkbox_item.attr("checked");
        // console.log("is_checked", is_checked);
        if (is_checked == "checked") {
            $(".checkbox-container").addClass("show-checkbox");
            $(".preference-single-inner-container").each(function() {
                var checkbox_item = $(this).find("input[type='checkbox']");
                checkbox_item.attr("checked", true);
            });
        } else {
            $(".checkbox-container").removeClass("show-checkbox");
            $(".preference-single-inner-container").each(function() {
                var checkbox_item = $(this).find("input[type='checkbox']");
                checkbox_item.attr("checked", false);
            });
        }
    });

    $(document).on("click", "header .preferences-container, .filter-section-mobile .preferences-container, .single-language-container", function (e) {
        e.preventDefault();
        // console.log($(this).attr("class"));
        if ($("#side-filter-popup").length > 0) {
            setTimeout(function() {
                loadPublishersFromLanguages();
            }, 100);
        } else if ($("#isLogged-popup").length > 0) {
            // signinModal.open();
            isLoggedModal.open();
        }
    });

    const loadPublishersFromLanguages = () => {
        $(".preferences-ajax-load").html('<div style="background-color:#FFFFFF;text-align:center;width:100%"><br><br><br><div class="lds-ring"><div></div><div></div><div></div><br><br><br></div>');
        let form = $(".languages-listing-inner-container").closest("form");
        let form_data = new FormData();
        let other_data = form.serializeArray();
        $.each(other_data, function (key, input) {
            form_data.append(input.name, input.value);
            // console.log(input.name + ":   " + input.value);
        });
        // console.log(form.attr("action"));
        form_data.append("ajaxLoad", true);
        $.ajax({
            url: form.attr("action"),
            type: "POST",
            dataType: "html",
            data: form_data,
            contentType: false,
            processData: false,
            success: function (result) {
                // console.log("result", result);
                $(".preferences-ajax-load").html(result);
            },
            error: function(Error) {
                console.log("Error", Error);
            }
        });
    }

    // Newsletter Subscribtion
    $("#subscribtion-check").on("change", function(event){
        event.preventDefault();
        var thisElem = $(this);
        let checkStatus = thisElem.is(":checked");
        const dataToSend = {
            _token: $('meta[name="csrf-token"]').attr("content"),
            email: $("#userEmail").val(),
            subscribed: checkStatus,
        }
        // console.log("data", data);
        if (checkStatus === true) {
            $.ajax({
                url: $("#VAR_BASE_URL_SUBSCRIBE").val(),
                type: "POST",
                data: dataToSend,
                success: function(response) {
                    // console.log(response, "response");
                    // console.log("Subscriber added:", response.message);
                    thisElem.attr("checked", response.subscribed);
                    thisElem.val(response.subscribed);
                    // alert(response.message);
                    if (response.subscribed == 0) {
                        unsubscribeModal.open();
                    } else {
                        subscribeModal.open();
                    }
                },
                error: function(error) {
                    // console.log(error, "error");
                    // console.error("Error subscribing:", error.message);
                    alert(error.message);
                }
            });
        } else {
            $.ajax({
                url: $("#VAR_BASE_URL_SUBSCRIBE").val(),
                type: "post",
                data: dataToSend,
                success: function(response) {
                    // console.log(response, "response");
                    // console.log("Subscriber removed:", response.message);
                    thisElem.attr("checked", response.subscribed);
                    thisElem.val(response.subscribed);
                    // alert(response.message);
                    if (response.subscribed == 0) {
                        unsubscribeModal.open();
                    } else {
                        subscribeModal.open();
                    }
                },
                error: function(error) {
                    // console.log(error, "error");
                    // console.error("Error unsubscribing:", error.message);
                    alert(error.message);
                }
            });
        }
    });
});
